import * as React from "react"
import type { SVGProps } from "react"
const SvgHeartPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M13.75 9.625c.345 0 .625.28.625.625v1.208h1.208a.625.625 0 1 1 0 1.25h-1.208v1.209a.625.625 0 1 1-1.25 0v-1.209h-1.208a.625.625 0 0 1 0-1.25h1.208V10.25c0-.345.28-.625.625-.625" />
    <path
      fillRule="evenodd"
      d="M17.49 3.76a5.011 5.011 0 0 0-7.086 0L10 4.162l-.404-.404a5.011 5.011 0 0 0-7.087 7.087l6.46 6.46c.57.57 1.493.57 2.062 0l.506-.506a5.208 5.208 0 0 0 6.829-7.13 5.013 5.013 0 0 0-.875-5.91m-7.698 8.323a3.958 3.958 0 1 1 7.916 0 3.958 3.958 0 0 1-7.916 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgHeartPlus
