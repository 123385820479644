import * as React from "react"
import type { SVGProps } from "react"
const SvgHeartOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M16.607 4.643a3.761 3.761 0 0 0-5.32 0L10 5.931 8.712 4.643a3.761 3.761 0 0 0-5.319 5.32l6.46 6.459a.208.208 0 0 0 .294 0l6.46-6.46a3.761 3.761 0 0 0 0-5.319m-6.203-.884a5.011 5.011 0 1 1 7.087 7.087l-6.46 6.46c-.57.57-1.493.57-2.062 0l-6.46-6.46A5.011 5.011 0 1 1 9.596 3.76l.404.404z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgHeartOutline
