"use client"

import { Listing } from "@store-platform/types"
import { Button, ButtonSize, ButtonVariant } from "../Button/Button"
import { useLaunchModal } from "../../hooks"
import { preventClickBubbleUp } from "@store-platform/utils"
import { HTMLAttributes } from "react"

export type LaunchButtonProps = {
  listing?: Listing
  variant?: ButtonVariant
  size?: ButtonSize
  label?: string
  className?: string
  trackerSource: string
} & HTMLAttributes<HTMLButtonElement>

export const LaunchButton = ({
  variant,
  size,
  label,
  listing,
  className,
  trackerSource,
}: LaunchButtonProps) => {
  const launchLinks = listing?.launchLinks
  const disabled = !launchLinks?.length

  const { open } = useLaunchModal()
  const openLaunchModal = () => {
    if (launchLinks && launchLinks?.length > 0) {
      open({
        domainOwner: listing?.developerDetails?.domainOwner ?? true,
        launchLinks,
        pwa: listing?.isPWA,
        launchTracker: trackerSource,
        listingId: listing?.id,
      })
    }
  }
  return (
    <Button
      onClick={(e) => {
        preventClickBubbleUp(e)
        openLaunchModal()
      }}
      variant={disabled ? "ghost" : variant || "default"}
      size={size || "sm"}
      tracker={{
        obj: "App",
        action: "Launcher Opened",
        properties: { source: trackerSource, listingId: listing?.id },
      }}
      className={className}
      disabled={disabled}
    >
      {label || "Get"}
    </Button>
  )
}
