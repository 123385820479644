// ------ from shadcn-ui --------
"use client"

import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { cn } from "@store-platform/utils"
import { ReactNode } from "react"

export interface TooltipProps {
  children: ReactNode
  caption: string
  side?: "top" | "right" | "bottom" | "left"
  className?: string
}

export const Tooltip = (props: TooltipProps) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={200}>
        <TooltipPrimitive.Trigger asChild>
          {props.children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            className={cn(
              "z-50 text-gray-900 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none",
              "shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]",
              props.className,
            )}
            sideOffset={5}
            side={props.side ?? "top"}
          >
            {props.caption}
            <TooltipPrimitive.Arrow className="fill-white" />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
