"use client"
import { preventClickBubbleUp } from "@store-platform/utils"
import { MouseEventHandler, useCallback } from "react"
import { Button, ButtonSize, ButtonVariant } from "../Button/Button"
import { selectIsAppFavorited, useAddToList, useAuthModal } from "../../hooks"
import { Listing } from "@store-platform/types"
import { SvgHeartOutline, SvgHeartPlus } from "@store-platform/ui/icons"

export const AddToListTrigger = ({
  listing,
  variant,
  className,
  size,
}: {
  listing: Listing
  variant?: ButtonVariant
  className?: string
  size?: ButtonSize
}) => {
  const { loggedIn, open: openAuthModal } = useAuthModal()
  const { addAppToFavorites, isFavorited, openAddToListModal } = useAddToList(
    (state) => ({
      addAppToFavorites: state.addAppToFavorites,
      isFavorited: selectIsAppFavorited(listing.id)(state),
      openAddToListModal: state.open,
    }),
  )

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      preventClickBubbleUp(event)

      if (!loggedIn) {
        openAuthModal()
      } else if (isFavorited) {
        openAddToListModal({ listing })
      } else {
        await addAppToFavorites(listing)
      }
    },
    [
      loggedIn,
      isFavorited,
      addAppToFavorites,
      openAuthModal,
      listing,
      openAddToListModal,
    ],
  )

  return (
    <Button
      variant={variant ?? "secondary"}
      className={className}
      size={size ?? "sm"}
      onClick={handleClick}
      name="Favorite"
    >
      {loggedIn && isFavorited ? (
        <SvgHeartPlus className="h-5 w-5" />
      ) : (
        <SvgHeartOutline className="h-5 w-5" />
      )}
    </Button>
  )
}
