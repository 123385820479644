"use client"

import {
  BaseModalStoreType,
  EnrichedList,
  List,
  Listing,
} from "@store-platform/types"
import { isType } from "@store-platform/utils"
import { first, omit } from "lodash-es"
import { create } from "zustand"

type ListAction = "add" | "remove" | "create"
type AddToListState = {
  lists: {
    [listId: string]: EnrichedList
  }
  addAppToList: (listId: string, slug: string) => Promise<void>
  removeAppFromList: (listId: string, slug: string) => Promise<void>
  createList: (name: string) => Promise<{ message?: string } | void>
  renameList: (
    listId: string,
    name: string,
  ) => Promise<{ error: { code: number } } | List>
  deleteList: (listId: string) => Promise<boolean>
  addAppToFavorites: (listing: Listing) => Promise<void>
  serverActions: ServerActions
  initState: (lists: EnrichedList[] | null, actions: ServerActions) => void
  resetState: () => void
}
type ModalState = {
  listing?: Listing
}

type ServerActions = {
  addAppToList?: (listId: string, appId: string, slug: string) => Promise<void>
  removeAppFromList?: (
    listId: string,
    appId: string,
    slug: string,
  ) => Promise<void>
  createList?: (
    name: string,
    appId: string,
  ) => Promise<{ message?: string; code?: number } | List>
  renameList?: (
    listId: string,
    name: string,
  ) => Promise<List | { error: { code: number } }>
  deleteList?: (listId: string) => Promise<boolean>
  addAppToFavorites?: (
    favoritesListId: string | undefined,
    appId: string,
  ) => Promise<List | undefined>
  removeAppFromFavorites?: (appId: string) => Promise<void>
}

const updateList = (
  state: AddToListState,
  listId: string,
  listing: Listing,
  action: ListAction,
): Partial<AddToListState> => {
  if (action === "add") {
    const list = state.lists[listId]
    return {
      lists: {
        ...state.lists,
        [listId]: {
          ...list,
          listings: [...list.listings, listing],
        },
      },
    }
  }
  if (action === "remove") {
    const list = state.lists[listId]
    return {
      lists: {
        ...state.lists,
        [listId]: {
          ...list,
          listings: list.listings.filter((lstng) => lstng.id !== listing.id),
        },
      },
    }
  }
  return state
}

export const useAddToList = create<
  BaseModalStoreType<ModalState> & AddToListState
>((set, get) => ({
  payload: {},
  lists: {},
  addAppToList: async (listId, slug) => {
    const listing = get().payload.listing || ({} as Listing)
    const saAddAppToList = get().serverActions.addAppToList
    if (!saAddAppToList) return
    // make the update optimistic
    set((state) => updateList(state, listId, listing, "add"))
    await saAddAppToList(listId, listing.appId, slug)
  },
  removeAppFromList: async (listId, slug) => {
    const listing = get().payload.listing || ({} as Listing)
    const saRemoveAppFromList = get().serverActions.removeAppFromList
    if (!saRemoveAppFromList) return
    // make the update optimistic
    set((state) => updateList(state, listId, listing, "remove"))
    await saRemoveAppFromList(listId, listing.appId, slug)
  },
  createList: async (name) => {
    const listing = get().payload.listing || ({} as Listing)
    const saCreateList = get().serverActions.createList
    if (!saCreateList) return
    const resp = await saCreateList(name, listing.appId)
    if (!isType<List>(resp, "id")) return { message: resp.message }
    set({
      lists: {
        ...get().lists,
        [resp.id]: {
          ...resp,
          listings: [listing],
          followerIds: [],
        } as EnrichedList,
      },
    })
  },
  renameList: async (listId, name) => {
    const saRenameList = get().serverActions.renameList
    if (!saRenameList) return { error: { code: -1 } }
    const resp = await saRenameList(listId, name)
    if ("error" in resp) return resp
    set((state) => ({
      lists: {
        ...state.lists,
        [listId]: {
          ...state.lists[listId],
          // Replace updated values (name, slug, etc)
          ...resp,
        },
      },
    }))
    return resp
  },
  deleteList: async (listId) => {
    const saDeleteList = get().serverActions.deleteList
    if (!saDeleteList) return false
    const success = await saDeleteList(listId)
    if (success) {
      set((state) => ({ lists: omit(state.lists, listId) }) as AddToListState)
    }
    return success
  },
  addAppToFavorites: async (listing: Listing) => {
    const saAddAppToFavorites = get().serverActions.addAppToFavorites
    if (!saAddAppToFavorites) return
    const existingFavoritesListId = selectFavoritesList(get())?.id
    const resp = await saAddAppToFavorites(
      existingFavoritesListId,
      listing.appId,
    )
    if (resp) {
      if (!existingFavoritesListId) {
        // add lists to store if not already there
        set({
          lists: {
            [resp.id]: { ...resp, listings: [], followerIds: [] },
          },
        })
      }
      set((state) => updateList(state, resp.id, listing, "add"))
    }
  },
  // Set by `AddToListProvider`
  serverActions: {},
  initState: (lists, actions) => {
    let listsKeyed: { [listId: string]: EnrichedList } = {}
    if (lists) {
      listsKeyed = lists.reduce<{ [listId: string]: EnrichedList }>(
        (acc, list) => {
          acc[list.id] = list
          return acc
        },
        {},
      )
    } else {
      listsKeyed = {}
    }

    set({ lists: listsKeyed, serverActions: actions })
  },
  resetState: () => {
    set({ lists: {}, serverActions: {} })
  },
  isOpen: false,
  open: ({ listing }) => set({ isOpen: true, payload: { listing } }),
  onOpenChange: (isOpen: boolean) => set({ isOpen }),
}))

export const selectLists = (state: AddToListState) => {
  return Object.values(state.lists).sort((a, b) => {
    if (a.listType === "favorites") return -1
    if (b.listType === "favorites") return 1

    return a.name.localeCompare(b.name)
  })
}

export const selectIsAppFavorited =
  (listingId: string) => (state: AddToListState) => {
    return selectLists(state)
      .filter((list) => list.listType === "favorites")
      .some((list) =>
        list.listings?.some((listing) => listing.id === listingId),
      )
  }

export const selectFavoritesList = (state: AddToListState) => {
  return first(selectLists(state))
}

export const selectIsAppInList =
  (listId: string, listingId: string) => (state: AddToListState) => {
    const list = state.lists[listId]
    return list.listings?.some((listing) => listing.id === listingId)
  }
