"use client"

import { cn } from "@store-platform/utils"
import Image from "next/image"
import { memo } from "react"

const PlaceholderIconUrl = "https://static.store.app/platform/missing-icon.png"

export interface AppIconProps {
  size: 48 | 96 | 144 | 192 | 256 | 384 | 512
  name?: string
  imgUrl?: string
  className?: string
  priority?: boolean
  transparentBg?: boolean
}

export const AppIcon = memo((props: AppIconProps) => {
  return (
    <div
      className={cn(
        "inline-flex aspect-square w-full rounded-lg relative",
        props?.className,
      )}
    >
      {props.imgUrl ? (
        <Image
          src={props.imgUrl || PlaceholderIconUrl}
          alt={`${props.name} app icon`.trimStart()}
          fill={true}
          sizes={`${props.size}px`}
          className={cn(
            "object-contain rounded-[inherit] aspect-square",
            props?.transparentBg
              ? "border-transparent border-0"
              : "bg-gray-50 border-gray-50 border-2",
          )}
          priority={props.priority}
          onError={(e) => {
            e.currentTarget.src = PlaceholderIconUrl
            e.currentTarget.srcset = ""
          }}
        />
      ) : (
        <Image
          src={PlaceholderIconUrl}
          alt="Missing app icon"
          width={props.size}
          height={props.size}
          className={cn(
            "rounded-[inherit] w-full flex items-center justify-center leading-none text-white",
            props?.transparentBg ? "bg-transparent" : "bg-gray-500",
          )}
        />
      )}
    </div>
  )
})
