"use client"
import React, { ReactNode } from "react"
import { cva } from "class-variance-authority"
import { cn } from "@store-platform/utils"

type BadgeGroupVariant = "default" | "wrap"

const badgeGroupVariants = cva("flex", {
  variants: {
    variant: {
      default: "flex-wrap space-x-0.5 max-h-[22px] overflow-hidden",
      wrap: "flex-wrap -mt-2 -mr-1.5",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})
/**
 * Takes in a list of badges and hides the ones that don't fit in the container. Make sure immediate children have forward refs so that the width can be measured here.
 * @param {string} variant - default: all badges are in a single line, wrap: badges wrap to the next line
 */
type BadgeGroupProps = {
  className?: string
  variant?: BadgeGroupVariant
  children: ReactNode
}

const BadgeGroup: React.FC<BadgeGroupProps> = ({
  className,
  variant,
  children,
  ...props
}) => {
  return (
    <div className={cn(badgeGroupVariants({ variant }), className)} {...props}>
      {children}
    </div>
  )
}

BadgeGroup.displayName = "BadgeGroup"

export { BadgeGroup }
