/**
 *
 * @param someVar - variable to check
 * @param properties - properties unique to type T
 */
export function isType<T>(
  someVar: any,
  properties: keyof T | Array<keyof T>,
): someVar is T {
  if (Array.isArray(properties)) {
    return properties.every((property) => property in someVar)
  } else {
    return properties in someVar
  }
}
